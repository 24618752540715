import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import parse from 'html-react-parser'
import worldMap from 'images/contact/contactUsMap.svg'
import { stripHTMLTags, jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  contactContainer,
  contactHeaderContent,
  heading,
  subTitle,
  mapWrapper,
  mapContainer,
  locationWrapper,
  locationContainer,
  locationInfo,
  contactInfo,
  contactIcon,
  contactSpecs,
  worldMapStyles
} from 'styles/contact.module.css'
import {
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Contact" }) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
            contactFieldGroup {
              contactLocations {
                locationName
                location {
                  contactIcon
                  contactText
                  contactMethodKey
                }
              }
            }
            title
          }
        }
      }
      menus(where: { location: FOOTER }) {
        edges {
          node {
            menuItems {
              edges {
                node {
                  id
                  url
                  label
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
const ContactPage = ({ data, pageContext }) => {
  const { pages } = data.wordPress
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description="Contact Us. We love helping organizations migrate to the cloud and watching how it transforms their businesses. Let's get you on the cloud"
        title='Contact Us'
      />
      <div className={contactContainer}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Contact'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={contactHeaderContent}>
            <h1 className={heading}>
              {pages.edges[0].node.headerSection.heroHeading}
            </h1>
            <p className={subTitle}>
              {pages.edges[0].node.headerSection.heroText}
            </p>
          </div>
        </div>
      </div>
      <div className={mapWrapper}>
        <div className={mapContainer}>
          <div className={locationWrapper}>
            {pages.edges[0].node.contactFieldGroup.contactLocations.map(
              (countryDetail, index) => (
                <div
                  className={locationContainer}
                  key={index.toString()}>
                  <div className={locationInfo}>
                    <h3>{countryDetail.locationName}</h3>
                    {countryDetail.location.map((location, index) => (
                      <div
                        className={contactInfo}
                        key={index.toString()}>
                        <span className={contactIcon}>
                          {parse(location.contactIcon)}
                        </span>
                        {location.contactMethodKey === 'address' && (
                          <span className={contactSpecs}>
                            {parse(location.contactText)}
                          </span>
                        )}
                        {location.contactMethodKey === 'email' && (
                          <span className={contactSpecs}>
                            <a href={`mailto:${stripHTMLTags(location.contactText)}`}>
                              {parse(location.contactText)}
                            </a>
                          </span>
                        )}
                        {location.contactMethodKey === 'telephone' && (
                          <span className={contactSpecs}>
                            <a href={`tel:${stripHTMLTags(location.contactText)}`}>
                              {parse(location.contactText)}
                            </a>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
          <div>
            <span className={worldMapStyles}>
              <img
                alt='worldMap'
                src={worldMap} />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default ContactPage
